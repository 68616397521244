import { React, useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Sjukrathjalfun(props) {
	const openInNewTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	const [language, setLanguage] = useState("icelandic");
	const translations = {
		icelandic: {
			title: "Sjúkraþjálfun",
			welcome: "Velkomin í Atlas endurhæfingu",
			address:
				"Við erum staðsett í Íþróttamiðstöðinni í Laugardal (Engjavegi 6) og í Laugardalshöll (Engjavegi 8), 104 Reykjavík. Til að bóka tíma hjá sjúkraþjálfara, hafðu samband í síma 5526600 eða sendu tölvupóst á afgreidsla@atlasendurhaefing.is.",
			request: "Beiðni um sjúkraþjálfun",
			healthInsuranceInfo:
				"Sjúkratryggingar Íslands (SÍ) greiða hluta af kostnaði við meðferð hjá sjúkraþjálfara. Frá 1. febrúar 2023 er hægt að koma til sjúkraþjálfara í 6 skipti án beiðni. Ef þarf að koma oftar en 6 skipti þarf að liggja fyrir beiðni frá lækni til að fá niðurgreitt frá SÍ.",
			individualRights:
				"Sjúkratryggður einstaklingur sem þarf á þjálfun að halda á rétt á allt að 15 meðferðarskiptum á 365 dögum talið frá fyrsta meðferðarskipti. Sjúkratryggður á einnig rétt á nauðsynlegri viðbótarþjálfun hafi Sjúkratryggingar Íslands samþykkt slíka meðferð.",
			unionContribution:
				"Öll helstu stéttarfélög Íslands taka þátt í kostnaði við sjúkraþjálfun. Einstaklingur greiðir sjálfur fyrir hvern sjúkraþjálfunartíma en fer svo með reikninginn til síns stéttarfélags og fær endurgreitt. Mismunandi er eftir stéttarfélögum hversu mikið er endurgreitt. Sum stéttarfélög vilja fá afrit af læknabeiðninni með reikningum.",
			pensionFunds:
				"Sjúkrasjóðir lífeyrissjóða taka sumir þátt í kostnaði vegna sjúkraþjálfunar. Best er að kanna málið hjá sínum sjóðum og félögum. Tryggingarfélögum ber eftir atvikum að greiða fyrir sjúkraþjálfun ef fólk verður fyrir slysi. Kannaðu málið hjá þínum tryggingarfélagi.",
			moreInfoLink: "Sjá nánar:",
			link: "www.island.is/s/sjukratryggingar",
			linkTitle: "www.island.is/s/sjukratryggingar",
			onePerDay:
				"Sjúkratryggingar Íslands niðurgreiða einungis 1 tíma á dag í sjúkraþjálfun.",
			under18Title: "Einstaklingar undir 18 ára",
			under18Info:
				"Börn 2ja-17 ára greiða ekkert gjald fyrir þjálfun gegn framvísun beiðni, annars greiða þau 30% af gjaldi sjúkratryggðra.",
		},
		english: {
			title: "Physiotherapy",
			welcome: "Welcome to Atlas endurhæfing",
			address:
				"We have clinics at Engjavegur 6, 104 Reykjavík, and at Engjavegur 8, 104 Reykjavík. To book an appointment with a physiotherapist call 5526600 or send an email to afgreidsla@atlasendurhaefing.is.",
			request: "Request for physiotherapy",
			healthInsuranceInfo:
				"The Icelandic Health Insurance (SÍ) covers a portion of the costs for treatment by a physiotherapist. From February 1, 2023, it is possible to see a physiotherapist up to 6 times without a request. If more than 6 visits are needed, a request from a doctor is required to get reimbursement from SÍ.",
			individualRights:
				"An insured individual who needs physiotherapy is entitled to up to 15 treatments within 365 days from the first treatment session. The insured also has the right to necessary additional physiotherapy if the Icelandic Health Insurance has approved such treatment.",
			unionContribution:
				"All major unions in Iceland contribute to the costs of physiotherapy. An individual pays for each physiotherapy session themselves but then takes the bill to their union and gets reimbursed. The amount of reimbursement varies among unions. Some unions require a copy of the medical request with the invoice.",
			pensionFunds:
				"Pension funds' health insurance in some cases participate in the cost of physiotherapy. It is best to check with your pension fund as responsibilities differ between funds. In certain circumstances, insurance companies are responsible for paying for physiotherapy if a person has been in an accident. Check with your insurance company.",
			moreInfoLink: "For more information, see:",
			link: "www.island.is/en/o/icelandic-health-insurance",
			linkTitle: "www.island.is/en/o/icelandic-health-insurance",
			onePerDay:
				"The Icelandic Health Insurance (SÍ) only subsidizes 1 appointment per day for physiotherapy.",
			under18Title: "Individuals under 18 years old",
			under18Info:
				"Children aged 2-17 pay no fee upon presentation of a request, otherwise, they pay 30% of the insured's fee.",
		},
		spanish: {
			title: "Fisioterapia",
			welcome: "Bienvenido a Atlas endurhæfing.",
			address:
				"Tenemos clínicas en Engjavegur 6, 104 Reykjavík, y en Engjavegur 8, 104 Reykjavík. Para reservar una cita con un fisioterapeuta, llame al 5526600 o envíe un correo electrónico a afgreidsla@atlasendurhaefing.is.",
			request: "Solicitud para fisioterapia",
			healthInsuranceInfo:
				"El Seguro de Salud de Islandia (SÍ) cubre una parte de los costos del tratamiento por un fisioterapeuta. A partir del 1 de febrero de 2023, es posible ver a un fisioterapeuta hasta 6 veces sin una solicitud. Si se necesitan más de 6 visitas, se requiere una solicitud de un médico para obtener reembolso de SÍ.",
			individualRights:
				"Un individuo asegurado que necesita fisioterapia tiene derecho a recibir hasta 15 tratamientos dentro de los 365 días posteriores a la primera sesión de tratamiento. El asegurado también tiene derecho a la fisioterapia adicional necesaria si el Seguro de Salud de Islandia ha aprobado dicho tratamiento.",
			unionContribution:
				"Todos los principales sindicatos en Islandia contribuyen a los costos de la fisioterapia. Un individuo paga por cada sesión de fisioterapia por sí mismo, pero luego lleva la factura a su sindicato y recibe un reembolso. La cantidad del reembolso varía entre los sindicatos. Algunos sindicatos requieren una copia de la solicitud médica con la factura.",
			pensionFunds:
				"Los seguros de salud de los fondos de pensiones, en algunos casos, participan en el costo de la fisioterapia. Es mejor consultar con su fondo de pensiones, ya que las responsabilidades difieren entre los fondos. En ciertas circunstancias, las compañías de seguros son responsables de pagar la fisioterapia si una persona ha tenido un accidente. Consulte con su compañía de seguros.",
			moreInfoLink: "Para obtener más información, consulte (Inglés):",
			link: "www.island.is/en/o/icelandic-health-insurance",
			linkTitle: "www.island.is/en/o/icelandic-health-insurance",
			onePerDay:
				"El Seguro de Salud de Islandia (SÍ) solo subsidia 1 cita al día para fisioterapia.",
			under18Title: "Individuos menores de 18 años",
			under18Info:
				"Los niños de 2 a 17 años no pagan tarifa al presentar una solicitud; de lo contrario, pagan el 30% de la tarifa del asegurado.",
		},
	};

	useEffect(() => {
		const { location } = props;
		if (location.state && location.state.lang) {
			setLanguage(location.state.lang);
		}
	}, [props]);

	return (
		<div className="Body">
			<div className="TitleWButton">
				<h1 className="TitleSjukra">{translations[language].title}</h1>
				<div className="titleButtons">
					<div className="btn-div-titles">
						<button
							onClick={() => setLanguage("icelandic")}
							className={`btn btn-border-pop ${
								language === "icelandic" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">Íslenska</p>
						</button>
						<button
							onClick={() => setLanguage("english")}
							className={`btn btn-border-pop ${
								language === "english" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">English</p>
						</button>
						<button
							onClick={() => setLanguage("spanish")}
							className={`btn btn-border-pop ${
								language === "spanish" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">Español</p>
						</button>
					</div>
				</div>
			</div>
			<div className="Sjukrathjalfun">
				<div className="SjukrathjalfunText">
					<h2>{translations[language].welcome}</h2>
					<p>{translations[language].address}</p>
					<h2> {translations[language].request} </h2>
					<p>{translations[language].healthInsuranceInfo}</p>
					<p>{translations[language].individualRights}</p>
					<p>{translations[language].unionContribution}</p>
					<p>{translations[language].pensionFunds}</p>
					<p>{translations[language].onePerDay}</p>
					<h3 className="SjukrathjalfunLink">
						{translations[language].moreInfoLink}{" "}
						<p
							className="Linkur"
							onClick={() =>
								openInNewTab(
									"https://island.is/en/o/icelandic-health-insurance"
								)
							}
						>
							www.island.is/en/o/icelandic-health-insurance
						</p>
					</h3>

					<h2> {translations[language].under18Title} </h2>
					<p>{translations[language].under18Info}</p>
				</div>
				<div className="SjukrathjalfunImage">
					<LazyLoadImage
						src={
							"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692181316/atlas2.0/images/sjukrathjalfun_waoepq.jpg"
						}
						alt="Sjúkraþjálfun"
						className="SjukrathjalfunSize"
						effect="blur"
					/>
				</div>
			</div>
		</div>
	);
}

export default Sjukrathjalfun;
