import React from "react";
import AllNews from "../../Json/news.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function News({ match }) {
	const openInNewTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	var date = "";
	var title = "";
	var text = "";
	var image = "";
	var url = "";
	var urlname = "";
	AllNews.find((news) => {
		if (match.params.id === news.id) {
			date = news.date;
			title = news.title;
			text = news.text;
			image = news.image;
			url = news.url;
			urlname = news.urlname;
		}
		return null;
	});
	return (
		<div className="Body">
			{title === "" ? (
				""
			) : (
				<div>
					<div className="NewsTitle">
						<h1 className="newsHeading"> {title} </h1>
						<h4 className="newsDate">{date}</h4>
					</div>

					<div className="NewsContent">
						<div className={"NewsRight"} style={{ whiteSpace: "pre-line" }}>
							<p> {text} </p>
							{urlname !== "" ? (
								<div className="OtherText">
									<p> Sjá nánar: </p>
									<p className="Linkur" onClick={() => openInNewTab(url)}>
										{" "}
										{urlname}
									</p>
								</div>
							) : (
								""
							)}
						</div>
						<div className="NewsLeft">
							{image === "" ? null : (
								<LazyLoadImage
									src={image}
									alt="Frétt"
									className="NewsImgSize"
									effect="blur"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default News;
