import React, { useEffect, useState } from "react";
import StadsetningSlider from "../ImageSlider/StadsetningSlider";
import Sjukrathjalfarar from "../../Json/sjukrathjalfarar.json";

function UmOkkur() {
	const [count, setCount] = useState(0);

	const emailTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};
	function countTitleOccurrences(title) {
		return Sjukrathjalfarar.filter((item) => item.title === title).length;
	}
	useEffect(() => {
		const sjukraCount = countTitleOccurrences("Sjúkraþjálfari");
		setCount(sjukraCount);
	}, []);

	return (
		<div>
			<div className="Body">
				<div className="Title">
					<h1 className="TitleUnderline"> Um Atlas Endurhæfingu </h1>
				</div>
				<div className="umOkkurTopSection">
					<div className="umOkkurSections">
						<div className="umOkkurRightSection">
							<p>
								Atlas endurhæfing starfar á sviði sjúkraþjálfunar,
								endurhæfingar, fræðslu og þjálfunar. Stöðin var stofnuð í júní
								2008 og eru eigendur hennar Pétur Einar Jónsson og Róbert
								Magnússon sjúkraþjálfarar.
							</p>

							<p>
								{count} sjúkraþjálfarar starfa hjá Atlas Endurhæfingu.
								Sjúkraþjálfarar fyrirtækisins sinna allri almennri sjúkraþjálfun
								en leggja metnað sinn í að sérhæfa sig á hinum ýmsu sviðum, má
								þar nefna:
							</p>
							<ul>
								<li>Íþróttasjúkraþjálfun</li>
								<li>Bakendurhæfingu</li>
								<li>Axlarvandamálum</li>
								<li>Hné- og axlarendurhæfingu</li>
								<li>Þjálfun eftir bæklunaraðgerðir</li>
							</ul>

							<p> Einnig er ýmis önnur þjónusta í boði og má þar nefna: </p>
							<ul>
								<li>Nálastungur</li>
								<li>Fræðslufyrirlestrar</li>
								<li>Ráðgjöf til íþróttafélaga</li>
								<li>Þolpróf og mjólkursýrumælingar</li>
							</ul>

							<p>
								Atlas endurhæfing kemur að sjúkraþjálfun hjá hinum ýmsu
								íþróttafélögum og landsliðum á Íslandi, bæði karla og kvenna á
								öllum aldri.
							</p>

							<div className="umOkkurInfo">
								<div>
									<h4> Sími </h4>
									<p> 552 6600 </p>
								</div>

								<div>
									<h4> Kennitala </h4>
									<p>490608-1410</p>
								</div>

								<div>
									<h4> Netfang </h4>
									<p
										className="HafaSambandLinkur"
										onClick={() =>
											emailTab("mailto:afgreidsla@atlasendurhaefing.is")
										}
									>
										afgreidsla@atlasendurhaefing.is
									</p>
								</div>

								<div>
									<h4> Heimilisfang </h4>
									<p>
										Engjavegur 6, 104 Reykjavík og Engjavegur 8, 104 Reykjavík
									</p>
								</div>
							</div>
						</div>
						<div className="umOkkurLeftSection">
							<StadsetningSlider />
						</div>
					</div>
				</div>
			</div>
			<div className="umOkkurImages2">
				<StadsetningSlider />
			</div>
		</div>
	);
}
export default UmOkkur;
