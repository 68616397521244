import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Engjavegur 8, 64.140575, -21.876685
const lat = 64.140575;
const lng = -21.876685;
const MapLaugardalsholl = (props) => {
	const getMapOptions = (maps) => {
		return {
			disableDefaultUI: false,
			mapTypeControl: true,
			streetViewControl: false,
			styles: [
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [{ visibility: "on" }],
				},
			],
		};
	};

	const [center] = useState({ lat: lat, lng: lng });
	const [zoom] = useState(15);
	return (
		<div style={{ height: "40vh", width: "100%" }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: API_KEY }}
				defaultCenter={center}
				defaultZoom={zoom}
				yesIWantToUseGoogleMapApiInternals
				options={getMapOptions}
			>
				<Marker lat={lat} lng={lng} name="Atlas Endurhæfing" color="red" />
			</GoogleMapReact>
		</div>
	);
};

export default MapLaugardalsholl;
