import React from "react";
import Map from "../Map/MapLaugardalsholl";
import { Link } from "react-router-dom";

function Laugardalsholl() {
	return (
		<div className="Body">
			<div className="Title">
				<h1> Atlas Laugardalshöll - Engjavegur 8, 104 Reykjavík </h1>
			</div>
			<div className="stadSections">
				<div className="stadRightSection">
					<p>
						Atlas Endurhæfing er með útibú í Laugardalshöllinni, Engjavegi 8.
						Gengið er inn um inngang A. Salur 4 á 2. hæð.
					</p>

					<h4>Athugið</h4>
					<p>Endurhæfing eftir liðskiptaaðgerðir hjá Klinik fara fram hér.</p>

					<h4> Opnunartími </h4>
					<p>
						Mánudaga - fimmtudaga 8:00 - 17:00
						<br />
						Föstudaga 8:00 - 16:00 <br />
					</p>
					<h4> Heimilisfang </h4>
					<p> Engjavegur 8, 104 Reykjavík </p>

					<h4> Sjá Atlas Íþróttamiðstöðinni í Laugardal </h4>
					<div className="btn-div-stads">
						<Link to="/umokkur/stadsetning" className="btn btn-border-pop">
							<p className="btnLabel">Atlas íþróttamiðstöðinni í Laugardal</p>
						</Link>
					</div>
				</div>

				<div className="stadLeftSection">
					<div className="map">
						<Map />
					</div>
				</div>
			</div>
			<div className="BottomSection">
				<img
					src={
						"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1736199107/atlas2.0/locations/laugardalsholl2.jpg"
					}
					alt="Laugardalshöll"
					className="stadImgSize"
				/>
			</div>
			<div className="BottomSection">
				<img
					src={
						"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1735858684/atlas2.0/locations/laugardalsholl.jpg"
					}
					alt="Laugardalshöll"
					className="stadImgSize"
				/>
			</div>
		</div>
	);
}

export default Laugardalsholl;
