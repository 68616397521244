import React from "react";

import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import "./Toolbar.css";
import Logo from "../../assets/image/AtlasLogoTransparentSide.png";

import { Link, NavLink, withRouter } from "react-router-dom";

const toolbar = (props) => {
	const isActive = (pathname, state = {}) => {
		const currentLocation = props.location;
		return (
			currentLocation.pathname === pathname &&
			(!state.lang ||
				(currentLocation.state && currentLocation.state.lang === state.lang))
		);
	};

	return (
		<header className="toolbar">
			<nav className="toolbar_navigation">
				<div className="toolbar_toggle-button">
					<DrawerToggleButton click={props.drawerClickHandler} />
				</div>
				<div className="toolbar_logo">
					<Link to="/">
						<img
							src={Logo}
							alt="Atlas"
							className="NavbarLogo"
							focusable="false"
						/>
					</Link>
				</div>
				<div className="spacer" />
				<div className="toolbar_navigation-items">
					<ul>
						<div className="sjukrathjalfun">
							<NavLink
								exact
								to={{
									pathname: "/sjukrathjalfun",
									state: { lang: "icelandic" },
								}}
								className={`mainNavLink ${
									isActive("/sjukrathjalfun", { lang: "icelandic" })
										? "activeTab"
										: ""
								}`}
							>
								Sjúkraþjálfun
							</NavLink>
							<ul>
								<li>
									<NavLink
										exact
										to="/sjukrathjalfun/gjaldskra"
										className="sjukrathjalfunUnderLink"
										activeClassName="activeTab"
									>
										Gjaldskrá
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										to={{
											pathname: "/sjukrathjalfun",
											state: { lang: "english" },
										}}
										className={`sjukrathjalfunUnderLink ${
											isActive("/sjukrathjalfun", { lang: "english" })
												? "activeTab"
												: ""
										}`}
									>
										English
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										to={{
											pathname: "/sjukrathjalfun",
											state: { lang: "spanish" },
										}}
										className={`sjukrathjalfunUnderLink ${
											isActive("/sjukrathjalfun", { lang: "spanish" })
												? "activeTab"
												: ""
										}`}
									>
										Español
									</NavLink>
								</li>
							</ul>
						</div>

						<div className="starfsfolk">
							<NavLink
								to="/starfsfolk"
								className="mainNavLink"
								activeClassName="activeTab"
							>
								Starfsfólk
							</NavLink>
						</div>

						<div className="umOkkur">
							<NavLink
								exact
								to="/umokkur"
								className="mainNavLink"
								activeClassName="activeTab"
							>
								Um okkur
							</NavLink>
							<ul>
								<li>
									<NavLink
										exact
										to="/umokkur/stadsetning"
										className="umOkkurUnderLink"
										activeClassName="activeTab"
									>
										Staðsetning
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										to="/umokkur/laugardalsholl"
										className="umOkkurUnderLink"
										activeClassName="activeTab"
									>
										Laugardalshöll
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										to="/umokkur/personuvernd"
										className="umOkkurUnderLink"
										activeClassName="activeTab"
									>
										Persónuvernd
									</NavLink>
								</li>
							</ul>
						</div>

						<div className="frettir">
							<NavLink
								to="/frettir"
								className="mainNavLink"
								activeClassName="activeTab"
							>
								Fréttir og greinar
							</NavLink>
						</div>

						<div className="hafasamband">
							<NavLink
								to="/hafasamband"
								className="mainNavLink"
								activeClassName="activeTab"
							>
								Hafa samband
							</NavLink>
						</div>
					</ul>
				</div>
			</nav>
		</header>
	);
};

export default withRouter(toolbar);
